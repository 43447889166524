@import '../variables';

/**
 *  REACT-TOGGLE
 */
.react-toggle {
  vertical-align: middle;
  &-track {
    width: 40px;
    background: none;
    border: 1px solid $gray-30;
    &-check,
    &-x {
      display: none;
    }
  }
  &-thumb {
    height: 16px;
    width: 16px;
    top: 4px;
    left: 4px;
    border: none;
    background: $gray-30;
    &:focus,
    &:hover,
    &:active {
      outline: none;
    }
  }
}

.react-toggle--checked {
  .react-toggle {
    &-track {
      background: none;
      border: 1px solid $green;
    }
    &-thumb {
      left: 20px;
      background: $green;
    }
  }
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb,
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background: none;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background: none;
}
