@import '~assets/sass/variables';

.kata {
  &-client-table {
    width: 100%;
    thead {
      border: solid 2px $gray-30;
      border-radius: 6px;
      background: $gray-10;
      tr {
        font-size: 14px;
        font-weight: bold;
        color: $gray-80;
        th {
          padding: 10px 24px;
          text-align: center;
        }
      }
    }
  }

  &-table {
    width: 100%;

    thead {
      border-top: solid 1px $gray-30;
      border-bottom: solid 2px $gray-30;

      th {
        padding: 16px;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: $gray-80;
      }
    }

    tbody {
      tr {
        border-bottom: solid 1px $gray-30;

        td {
          padding: 14px 16px;
          vertical-align: middle;

          color: $gray-70;
        }
      }
    }

    &--striped {
      tbody {
        tr {
          &:nth-child(odd) {
            background: $gray-10;
          }
        }
      }
    }

    &--hover {
      tbody {
        tr {
          &:hover {
            background: $gray-10;
          }
        }
      }
    }
  }
}
