//
// Grid styles copied from bootstrap
// --------------------------------------------------

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
