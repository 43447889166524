@import '~assets/sass/variables';
/**
* Rangeslider
*/

.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
  display: block;

  .rangeslider__fill {
    display: block;
  }

  .rangeslider__handle {
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    position: absolute;

    .rangeslider__active {
      opacity: 1;
    }
  }

  .rangeslider__handle-tooltip {
    width: 24px;
    height: 24px;
    text-align: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    font-size: 11px;
    transition: all 100ms ease-in;
    border-radius: 3px;
    display: inline-block;
    color: white;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    opacity: 0.9;

    span {
      margin-top: 12px;
      display: inline-block;
      line-height: 100%;
    }

    &:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
    }
  }
}

.rangeslider-horizontal {
  height: 6px;
  border-radius: 3px;

  .rangeslider__fill {
    height: 100%;
    background-color: $kata-blue;
    border-radius: 3px;
    top: 0;
  }

  .rangeslider__handle {
    width: 24px;
    height: 24px;
    position: absolute;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    border-radius: 50%;
    border: solid 3px $soft-kata-blue;
    background-color: #fff;
    touch-action: pan-x;
    top: -150%;
    text-align: center;
    margin-left: -2px;
    padding-top: 2px;
    box-shadow: inset 0px 0px 0px 1px $kata-blue;
  }

  .rangeslider__handle-tooltip {
    top: -40px;

    &:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid rgba(0, 0, 0, 0.8);
      left: 50%;
      bottom: -8px;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

.rangeslider__handle {
  &:focus {
    outline: none;
  }
}

.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;

  .rangeslider__fill {
    position: absolute;
    width: 100%;
    background-color: #7cb342;
    box-shadow: none;
    bottom: 0;
  }

  .rangeslider__handle {
    position: absolute;
    width: 30px;
    height: 10px;
    left: -10px;
    box-shadow: none;
  }

  .rangeslider__handle-tooltip {
    left: -100%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    &:after {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid rgba(0, 0, 0, 0.8);
      left: 100%;
      top: 12px;
    }
  }

  .rangeslider__labels {
    position: relative;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    text-align: left;
    width: 250px;
    height: 100%;
    left: 10px;

    .rangeslider__label-item {
      position: absolute;
      transform: translate3d(0, -50%, 0);

      &::before {
        content: '';
        width: 10px;
        height: 2px;
        background: black;
        position: absolute;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
      }
    }
  }
}

.rangeslider-reverse.rangeslider-horizontal {
  .rangeslider__fill {
    right: 0;
  }
}

.rangeslider-reverse.rangeslider-vertical {
  .rangeslider__fill {
    top: 0;
    bottom: inherit;
  }
}

.rangeslider__labels {
  position: relative;

  .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);

    &:first-child {
      right: 0;
      left: unset !important;
      transform: unset !important;
    }
  }
}

.rangeslider__handle-label {
  font-size: 11px;
  line-height: 15px;
}
